import * as login from './login';
import * as orders from './orders';
import * as cart from './cart';

const Api = {
  ...login,
  ...orders,
  ...cart,
};

export default Api;
