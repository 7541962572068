import axios from './axios';

export const login = async (token, platform) => {
  const { data } = await axios.post('/login', {
    token,
    platform,
  });
  return data;
};

export const loginAsGuest = async () => {
  const { data } = await axios.post('/loginGuest', {});
  return data;
};

export const loginPhone = async (payload) => {
  const { data } = await axios.post('/loginPhone', payload);
  return data;
};

export const verifyPhone = async (payload) => {
  const { data } = await axios.post('/verifyPhone', payload);
  return data;
};

export const loginGetToken = async (token, platform) => {
  const { data } = await axios.post('/loginGetToken', {
    token,
    platform,
  });
  return data;
};

export const loginUsingToken = async (token) => {
  const { data } = await axios.post('/loginUsingToken', {
    token,
  });
  return data;
};

export const logout = async () => {
  const { data } = await axios.post('/logout');
  return data;
};

export const fillProfile = async (payload) => {
  const { data } = await axios.post('/user/fillProfile', payload);
  return data;
}
