import { useEffect, useRef } from 'react';
import { FONTS } from 'ui/constants/font';

export function useAutoFontSize(themeName, text, defaultSize=16) {
  const ref = useRef();

  useEffect(() => {
    const width = ref?.current?.clientWidth;
    if (!width) {
      return;
    }
    const fontFamily = FONTS[themeName] || FONTS.celosia;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let size = defaultSize;
    while (size > 6) {
      context.font = `600 ${size}px ${fontFamily}`;
      const { width: ctxWidth } = context.measureText(text);
      if (ctxWidth < width) {
        break;
      }
      size--;
    }
    ref.current?.style?.setProperty('font-size', `${size}px`, 'important');
  }, [ref?.current?.clientWidth, themeName, text]);

  return ref;
}
