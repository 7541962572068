import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Avatar } from '@mui/material';
import { Button } from 'phoenix-components';
import { useRouter } from 'next/router';
import { useQueryParams } from 'hooks/location';
import { useInfiniteRoom } from 'hooks/chat';
import { useLanguageLoader } from 'contexts/language';
import { getShopIcon } from 'components/utils/icon';
import Header from 'components/shared/Header';
import styles from './ChatRoomDrawer.module.css';
import { SendMessage } from './SendMessage';
import dayjs from 'dayjs';

const formatAt = (at) => dayjs(at).format('MMMM DD YYYY, h:mm a');

export function ChatRoomDrawer({ roomId, shop }) {

  const history = useRouter();
  const params = useQueryParams();
  const lang = useLanguageLoader();
  const [chats, , loadMore, poll, hasMore, loading] = useInfiniteRoom(roomId);

  useEffect(() => {

    const container = document.getElementById('chatRoom');
    function handleResize() {

      if (container) {

        container.style.height = `${window.innerHeight - 204}px`;

      }

    }

    window.addEventListener('resize', handleResize);
    handleResize();

    if (container) {

      setTimeout(() => {

        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });

      }, 500);

    }

  });

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={() => {

        params.delete('openChat');
        history.push({ search: params.toString() });

      }}
    >
      <div className={styles.container}>
        <Header
          showCart={false}
          headerStyles={styles.headerStyles}
          showBack={true}
          showShop={true}
          showLogout={false}
          isProfile={true}
          showMenu={true}
          isChatPage={true}
          onBack={() => {

            params.delete('openChat');
            history.push({ search: params.toString() });

          }}
          shop={shop}
          label={lang('store.words.chat')}
        />
        <div className={styles.body}>
          <div className={styles.shopSection}>
            <Avatar src={getShopIcon(shop?.url)} className={styles.shop}> S </Avatar>
            <div className={styles.name}>
              {shop?.name || 'seller'}
            </div>
          </div>
          <div className={styles.chatRoom} id="chatRoom">
            {hasMore && chats.length > 0 && (
              <div className={styles.loadMoreContainer}>
                <Button
                  size="small"
                  onClick={loadMore}
                  label={lang('buttons.loadMore')}
                />
              </div>
            )}
            {chats.map(({ key, data: list }) => (
              <div key={key} className={styles.dateBlock}>
                {/* <div className={styles.dateHeading}>{key}</div> */}
                {list.map(chat => (
                  <div key={chat._id} className={styles.chat}>
                    {chat.by === 'you' ? (
                      <div className={styles.you}>
                        <div className={styles.youSec}>
                          <div className={styles.nameImgSec}>
                            <div className={styles.message}>
                              {chat.message.data}
                            </div>
                            <div className={styles.at}>{formatAt(chat.at)}</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.otherUser}>
                        <div className={styles.youSec}>
                          <div className={styles.nameImgSec}>
                            <div className={styles.message}>
                              {chat.message.data}
                            </div>
                          </div>
                          <div className={styles.at}>{formatAt(chat.at)}</div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            {chats.length === 0 && !loading && (
              <div className={styles.emptySec}>
                <img className={styles.noicon} src="/images/chat/empty.svg" alt="" />
                <div className={styles.noMsg}>  {lang('store.headings.noMessage')} </div>
                <div className={styles.noMsg1}>
                  {' '}
                  {lang('store.headings.noConversation')}
                  {' '}
                </div>
              </div>
            )}
          </div>

        </div>
        <SendMessage
          roomId={roomId}
          refresh={poll}
        />
      </div>
    </Drawer>
  );

}

ChatRoomDrawer.propTypes = {
  roomId: PropTypes.string.isRequired,
  shop: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

ChatRoomDrawer.defaultProps = {};
