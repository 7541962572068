import {
  aboutUsIcon,
  addressBookIcon,
  cartIcon,
  collectionsIcon,
  informationIcon,
  logoutIcon,
  messageIcon,
  myAccountIcon,
  ordersIcon,
  paymentsIcon,
  storeInfoIcon,
  storeTimingsIcon,
  tncIcon,
  testimonialIcon
} from './menuIcons'
import {useIsCustomDomain, useIsGuest} from 'contexts/profile';
import ShopUtils from 'ui/lib/shopUtils';
import {useRouter} from "next/router";

const MENU = [
  {
    title: 'Collections',
    langKey: 'store.headings.collections',
    icon: collectionsIcon(),
    items: [],
  },
  {
    title: 'My Account',
    langKey: 'store.menu.myAccount',
    items: [
      {
        title: 'Address Book',
        langKey: 'store.words.addressBook',
        route: '',
        icon: addressBookIcon(),
        shallow: true,
      },
      {
        title: 'Orders',
        langKey: 'store.menu.orders',
        route: '/orders',
        icon: ordersIcon(),
      },
      {
        title: 'Messages',
        langKey: 'store.menu.messages',
        route: '',
        icon: messageIcon(),
      },
      {
        title: 'Logout',
        langKey: 'store.menu.logout',
        route: '/',
        icon: logoutIcon(),
      },
      {
        title: 'Login',
        langKey: 'store.menu.login',
        route: '/',
        icon: logoutIcon(),
      },
    ],
    icon: myAccountIcon(),
  },
  {
    title: 'Cart',
    langKey: 'store.words.cart',
    icon: cartIcon(),
    items: [],
    route: '/cart',
  },
  {
    title: 'Customer Testimonials',
    langKey: 'store.words.customerTestimonials',
    icon: testimonialIcon(),
    items: [],
    route: '#shopTestimonials',
  },
  {
    title: 'Information',
    langKey: 'store.menu.information',
    items: [
      {
        title: 'About Us',
        langKey: 'store.menu.aboutUs',
        route: '#shopAboutUs',
        icon: aboutUsIcon(),
      },
      {
        title: 'Shop Policies',
        langKey: 'store.menu.termsPrivacy',
        route: '#shopTnc',
        icon: tncIcon(),
      },
      {
        title: 'Store Timings',
        langKey: 'store.menu.storeTimings',
        route: '#shopStoreTimings',
        icon: storeTimingsIcon(),
      },
      {
        title: 'Payment Methods',
        langKey: 'store.menu.paymentMethod',
        route: '#shopPayments',
        icon: paymentsIcon(),
      },
      {
        title: 'Store Information',
        langKey: 'store.headings.storeInformation',
        route: '#shopStoreInfo',
        icon: storeInfoIcon(),
      },
    ],
    icon: informationIcon(),
  },
];


function handleInfoMenu(shop, isCustomDomain, route, menu) {
  const regex = /(<([^>]+)>)/ig;
  const isFilledAboutUs = !!shop?.about?.replace(regex, '').length > 0;
  const isFilledTerms = shop?.tncs?.length > 0;

  const isHomePage = !isCustomDomain && route === '/[slug]' || isCustomDomain && route === '/';

  const isStoreInfoEnabled = ShopUtils.isSectionEnabled(shop, 'storeInformation');
  const shopTimingsEnabled = ShopUtils.isSectionEnabled(shop, 'shopTimings');
  const paymentMethodsEnabled = ShopUtils.isSectionEnabled(shop, 'paymentMethods');

  if (menu.length === 0) {

    return {
      hash: route,
      route: isCustomDomain ? '/' : `/${shop.slug}`,
    }

  }

  return menu.filter(item => {

    if (item.route === '#aboutUs') {

      return isFilledAboutUs;

    }
    if (item.route === '#shopTnc') {

      return isFilledTerms;

    }

    if (item.route === '#shopStoreInfo') {
      return isStoreInfoEnabled;
    }

    if (item.route === '#shopStoreTimings') {
      return shopTimingsEnabled;
    }
    if (item.route === '#shopPayments') {
      return paymentMethodsEnabled;
    }
    return true;

  }).map(item => ({
    ...item,
    hash: item.route,
    route: isCustomDomain ? '/' : `/${shop.slug}`,
    shallow: isHomePage,
  }))

}


function getParams(title) {

  switch (title) {

    case 'Address Book' :
      return 'address';
    case 'Messages':
      return 'openChat';
    case 'Customer Testimonials':
      return 'testimonials';
    default :
      return '';

  }

}

function changeOrderRoute(isCustomDomain, shop, item) {
  if (item.title === 'Orders') {
    if (isCustomDomain) {
      return '/orders';
    }
    return `/${shop.slug}/orders`;
  }
  return item.route;
}

function handleMyAccountMenu(shop, isCustomDomain, route, menu, isDirect) {
  return menu.map(item => ({
    ...item,
    route: changeOrderRoute(isCustomDomain, shop, item),
    params: getParams(item.title),
    value: shop.slug,
    isDirect: isDirect
  }));
}


export function useMenu(shop, isVisitor) {
  const isGuest = useIsGuest();
  const isCustomDomain = useIsCustomDomain();
  const router = useRouter();
  const isDirect = ShopUtils.isDirectCheckout(shop)
  const {route} = router;
  const testimonalsEnabled = ShopUtils.isSectionEnabled(shop, 'testimonials');
  const showTestimonials = testimonalsEnabled && shop?.uiConfig?.testimonials?.length > 0;

  const feedTitle = ShopUtils.getTitle(shop, 'collections');

  const savedTitle = 'store.headings.collections' === feedTitle ?
    'store.headings.collections' : feedTitle;

  console.log('savedTitle', savedTitle);

  return MENU.map(menu => {

    switch (menu.title) {

      case 'Information': {

        return {
          ...menu,
          items: handleInfoMenu(shop, isCustomDomain, route, menu.items)
        }

      }
      case 'Customer Testimonials': {

        if (!showTestimonials) {
          return null;
        }

        return {
          ...menu,
          items: handleInfoMenu(shop, isCustomDomain, route, menu.items)
        }

      }

      case 'Cart' : {

        return {
          title: menu.title,
          icon: menu.icon,
          items: [],
          langKey: menu.langKey,
          route: isCustomDomain ? `/cart` : `/${shop.slug}/cart`,
        }

      }

      case 'Collections' : {

        return {
          title: menu.title,
          icon: menu.icon,
          items: [],
          langKey: savedTitle,
          route: isCustomDomain ? `/catalogList` : `/${shop.slug}/catalogList`,
        }

      }

      case 'My Account' : {

        const items = handleMyAccountMenu(shop, isCustomDomain, route, menu.items, isDirect);

        const filtered = (isVisitor || isGuest) ? items.filter(x => x.title !== 'Logout')
          : items.filter(x => x.title !== 'Login');

        return {
          ...menu,
          items: filtered
        }

      }


      default:
        return menu;

    }

  }).filter(Boolean);

}

export default MENU;
