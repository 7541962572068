import React, { useState, useEffect } from 'react';
import { ChatRoomDrawer } from 'components/shared/ChatRoomDrawer/ChatRoomDrawer';
import { useRouter } from 'next/router';
import { useQueryParams } from 'hooks/location';
import { useShopChat, useOrderChat } from 'hooks/chat';
import {
  useIsVisitor, useShowLogin, isUserIdle, useIsGuest, useProfile, useProfileEmail
} from 'contexts/profile';
import {
  Typography,
} from '@mui/material';
import {chatIcon, chatIconDesktop, whatsappIcon,
  chatIcon2, chatIconDesktop2, whatsappIcon2} from 'components/shared/Icons/Chat';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import styles from './CustomShopChat.module.css';
import dynamic from 'next/dynamic';
import { useIsDesktop } from 'contexts/profile';
import { useToggle } from 'hooks/common';

const UserDetailsPopup = dynamic(() => import('components/userdetails/UserDetailsPopup'), {});

function CustomShopChat({
  shop,
  rId,
  shopId,
  className
}) {

  const history = useRouter();
  const [roomId, setRoomId] = useState(-1);
  const [unread, setUnread] = useState(0);
  const showLogin = useShowLogin(shop);
  const isVisitor = useIsVisitor();
  const params = useQueryParams();
  const [getOrderRoom] = useOrderChat();
  const [getShopRoom] = useShopChat();
  const isIdle = isUserIdle();
  const openChat = params.has('openChat');
  const [openUserDetails, toggleUserDetails] = useToggle();
  const isGuest = useIsGuest();
  const isDesktop = useIsDesktop();
  const { uiConfig: { chat } = {} } = shop;
  const guestChatEnabled = chat?.guestEnabled
  const iconDesktop = chat?.chatConfig?.desktop?.icon === 'whatsapp';
  const iconMobile = chat?.chatConfig?.mobile?.icon === 'whatsapp';
  const desktopIcon = chat?.chatConfig?.desktop?.icon;
  const positionDesktop = chat?.chatConfig?.desktop?.position === 'bottom-right';
  const positionMobile = chat?.chatConfig?.mobile?.position === 'bottom-right';
  const topRightDesk = chat?.chatConfig?.desktop?.position === 'top-right';
  const topRightMob = chat?.chatConfig?.mobile?.position === 'top-right';
  const desktopIcon2 = chat?.chatConfig?.desktop?.icon;
  const whatsappNumber = shop?.phone;
  const profile = useProfile();
  const email = useProfileEmail();

  useEffect(() => {

    if (isVisitor) {

      return;

    }
    if (!openChat) {

      setUnread(0);
      return;

    }
    if (rId !== -1) {

      setRoomId(rId);
      return;

    }
    if (shopId !== -1) {

      getShopRoom(shopId)
        .then(x => {

          setRoomId(x?.roomId);

        });

    }

  }, [openChat]);

  useEffect(() => {

    if (isVisitor || isIdle) {

      return;

    }
    const interval = setInterval(async () => {

      if (roomId !== -1) {

        getOrderRoom(0, roomId)
          .then(x => {

            setUnread(x?.unread);

          });

      }
      if (shopId !== -1) {

        const x = await getShopRoom(shopId);
        setUnread(x?.unread);
        setRoomId(x?.roomId);

      }

    }, 15000);
    return () => {

      clearInterval(interval);

    };

  }, [roomId, isVisitor]);

  const isGuestRegistered = () => {
    const nameFilled = profile?.name && profile?.name !== 'Guest';
    if ((email?.length || 0) > 0 && nameFilled) {
      return true;
    }
    if ((profile?.phone || 0).length > 0 && nameFilled) {
      return true;
    }
    return false;
  };

  const openChatRoom = () => {
    params.set('openChat', 'true');
    history.push({ search: params.toString() });
  };

  const getGuestchatOption = () => {
    if( (isDesktop && iconDesktop) || (!isDesktop && iconMobile) ){
      return window.open(`https://wa.me/${whatsappNumber}?text=Hi%20${shop?.slug}`);
    }
    else{
      return openChatRoom()
    }
  }
  const handleDetailsClose = () => {
    toggleUserDetails();
    openChatRoom();
  };

  const openWhatsapp = () => {
    if( (isDesktop && iconDesktop) || (!isDesktop && iconMobile) ){
      window.open(`https://wa.me/${whatsappNumber}?text=Hi%20${shop?.slug}`);
      return;
    }
    return;
  };
  return (
    <Typography component="div" className={styles.container}>
      {!isDesktop && topRightMob && (
        <Clickable
          className={cx(styles.chatButton, className)}
          onClick={() => {
            if (isVisitor) {
              openWhatsapp();
              showLogin();
              return;
            }
            if (isGuest) {
              if (guestChatEnabled) {
                isGuestRegistered() ? getGuestchatOption() : toggleUserDetails();
                return;
              } else {
                showLogin();
                return;
              }
            }
            openWhatsapp();
            openChatRoom();
          }}
        >
          {isDesktop ? (
            <>
              {iconDesktop ? (whatsappIcon())
                :
                (desktopIcon === 'icon2' ? chatIconDesktop() : chatIcon()
                )}
            </>
          ) : (
            <>
              {iconMobile ? whatsappIcon() : chatIcon()}
            </> )}
          {unread > 0 && (
            <div className={styles.unread}>
              {unread}
                  +
            </div>
          )}
        </Clickable>
      )}
      {topRightDesk && isDesktop &&(
        <Clickable
          className={cx(styles.chatButton, className)}
          onClick={() => {
            if (isVisitor) {
              openWhatsapp();
              showLogin();
              return;
            }
            if (isGuest) {
              if (guestChatEnabled) {
                isGuestRegistered() ? getGuestchatOption() : toggleUserDetails();
                return;
              } else {
                showLogin();
                return;
              }
            }
            openWhatsapp();
            openChatRoom();
          }}
        >
          {isDesktop ? (
            <>
              {iconDesktop ? (whatsappIcon())
                :
                (desktopIcon === 'icon2' ? chatIconDesktop() : chatIcon()
                )}
            </>
          ) : (
            <>
              {iconMobile ? whatsappIcon() : chatIcon()}
            </> )}
          {unread > 0 && (
            <div className={styles.unread}>
              {unread}
                  +
            </div>
          )}
        </Clickable>
      )}
      {positionDesktop && isDesktop && (
        <Clickable
          className={cx(styles.chatButtonPosition, className)}
          onClick={() => {
            if (isVisitor) {
              openWhatsapp();
              showLogin();
              return;
            }
            if (isGuest) {
              if (guestChatEnabled) {
                isGuestRegistered() ? getGuestchatOption() : toggleUserDetails();
                return;
              } else {
                showLogin();
                return;
              }
            }
            openWhatsapp();
            openChatRoom();
          }}
        >
          {isDesktop ? (
            <>
              {iconDesktop ? (whatsappIcon2())
                :
                (desktopIcon2 === 'icon2' ? chatIconDesktop2() : chatIcon2()
                )}
            </>
          ) : (
            <>
              {iconMobile ? whatsappIcon2() : chatIcon2()}
            </> )}
          {unread > 0 && (
            <div className={styles.unread}>
              {unread}
                  +
            </div>
          )}
        </Clickable>
      )}
      {positionMobile && !isDesktop && (
        <Clickable
          className={cx(styles.chatButtonPosition, className)}
          onClick={() => {
            if (isVisitor) {
              openWhatsapp();
              showLogin();
              return;
            }
            if (isGuest) {
              if (guestChatEnabled) {
                isGuestRegistered() ? getGuestchatOption() : toggleUserDetails();
                return;
              } else {
                showLogin();
                return;
              }
            }
            openWhatsapp();
            openChatRoom();
          }}
        >
          {isDesktop ? (
            <>
              {iconDesktop ? (whatsappIcon2())
                :
                (desktopIcon2 === 'icon2' ? chatIconDesktop2() : chatIcon2()
                )}
            </>
          ) : (
            <>
              {iconMobile ? whatsappIcon2() : chatIcon2()}
            </> )}
          {unread > 0 && (
            <div className={styles.unread}>
              {unread}
                  +
            </div>
          )}
        </Clickable>
      )}
      {openChat && roomId !== -1 && (
        <ChatRoomDrawer
          shop={shop}
          roomId={roomId}
        />
      )}
      {openUserDetails && <UserDetailsPopup
        onSubmit={handleDetailsClose}
        shop={shop}
        onClose={toggleUserDetails}
      />}
    </Typography>
  );

}

CustomShopChat.defaultProps = {
  rId: -1,
  className: '',
  shopId: -1,
};

CustomShopChat.propTypes = {
  slug: PropTypes.any.isRequired,
  shop: PropTypes.any.isRequired,
  rId: PropTypes.any,
  className: PropTypes.any,
  shopId: PropTypes.any,
};

export default CustomShopChat;
