import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { SnackBar } from 'ui/services';
import { useLanguageLoader } from 'contexts/language';
import { useChatSendMessage } from 'hooks/chat';
import styles from './SendMessage.module.css';

export function SendMessage({ refresh, roomId }) {

  const [message, setMessage] = useState('');
  const [addMessage] = useChatSendMessage();
  const lang = useLanguageLoader();

  const onFocus = () => {

    const container = document.getElementById('chatRoom');
    if (container) {

      setTimeout(() => {

        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      
      }, 500);
    
    }
  
  };

  const sendMessage = async () => {

    if (message.length === 0) {

      return;
    
    }
    try {

      await addMessage(roomId, {
        message: {
          type: 'text',
          data: message
        }
      });
      setMessage('');
      setTimeout(refresh, 500);
    
    } catch (e) {

      SnackBar.show('something went wring', 'error');
    
    }
  
  };

  return (
    <div className={styles.container}>
      <TextField
        fullWidth
        placeholder={lang('store.headings.typeYourMessage')}
        size="small"
        className={styles.field}
        variant="outlined"
        value={message}
        onFocus={onFocus}
        onChange={e => setMessage(e.target.value)}
      />
      <div role="none" onClick={sendMessage} className={styles.button}>
        <img src="/images/orders/send.svg" alt="" />
      </div>
    </div>
  );

}

SendMessage.propTypes = {
  refresh: PropTypes.func.isRequired,
  roomId: PropTypes.string.isRequired,
};

SendMessage.defaultProps = {};
