/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import { useQueryParams } from 'hooks/location';
import { useRouter } from 'next/router';
import {
  useIsGuest,
  useIsVisitor,
  useProfile,
  useProfileEmail,
  useRefreshProfile,
  useShowLogin
} from 'contexts/profile';
import Api from 'ui/api';
import { Loader, SnackBar } from 'ui/services';
import styles from './Menu.module.css';
import { useMenu } from './menuList';
import { closeIcon, } from 'components/utils/icon';
import { chevronUp } from './menuIcons';
import { useLanguageLoader } from 'contexts/language';
import CustomShopChat from 'components/shop/CustomShopChat';
import FollowUsAndMore from 'components/shop/FollowUsAndMore';
import { get } from "ui/lodash";
import dynamic from 'next/dynamic';
import { useToggle } from "hooks/common";

const Address = dynamic(() => import('components/address/Address'), {
});
const UserDetailsPopup = dynamic(() => import('components/userdetails/UserDetailsPopup'), {});

const loginTitles = ['Address Book', 'Orders', 'Messages', 'Logout', 'Cart'];

function Menu({ onClose, type, shop }) {

  const showLogin = useShowLogin(shop);
  const isVisitor = useIsVisitor();
  const isGuest = useIsGuest();
  const lang = useLanguageLoader();
  const router = useRouter();
  const params = useQueryParams();
  const email = useProfileEmail();
  const profile = useProfile();
  const showAddress = params.has('address');
  const showChat = params.has('openChat');
  const [index, setIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const menuList = useMenu(shop, isVisitor);
  const refreshProfile = useRefreshProfile();
  const [openUserDetails, toggleUserDetails] = useToggle();
  const { uiConfig: { chat } = {} } = shop;
  const chatEnabled = chat?.enabled
  const guestChatEnabled = chat?.guestEnabled

  const showBanner = !get(shop, 'featureConfig.banner', true);

  const classes = open ? styles.show : '';
  function toggle(index, setOpen, setIndex, open) {
    setIndex(index);
    setOpen(open);
  }

  const handleTestimonials = (menu) => {

    router.push({
      pathname: menu.items.route,
      hash: menu.route,
      search: menu?.params ? params.toString() : null,
    }, undefined, {
      shallow: !!menu?.shallow,
    })
  }

  const logout = async () => {
    Loader.show();
    try {
      await Api.logout();
      params.delete('openMenu');
      router.push({
        search: params.toString(),
      }, undefined, {
        shallow: true,
      });
      await refreshProfile();
    } catch (e) {
      SnackBar.showError(e);
    } finally {
      Loader.hide();
    }
  };

  const isGuestRegistered = () => {
    const nameFilled = profile?.name && profile?.name !== 'Guest';
    if ((email?.length || 0) > 0 && nameFilled) {
      return true;
    }
    if ((profile?.phone || 0).length > 0 && nameFilled) {
      return true;
    }
    return false;
  };


  const handleOnClick = (item) => async () => {
    if (loginTitles.includes(item.title)) {
      if (isVisitor) {
        showLogin();
        return;
      }
      if (isGuest) {
        if (guestChatEnabled && item.title === 'Messages') {
          isGuestRegistered() ?
            (() => {
              params.delete('openMenu');
              params.set('openChat', 'true')
              router.push({ search: params.toString() });
            })()
            : toggleUserDetails();
          return;
        } else if (item.title === 'Cart') {
          router.push({
            pathname: item.route,
            hash: item.hash,
            search: item?.params ? params.toString() : null,
          }, undefined, {
            shallow: !!item.shallow,
          })
        }else {
          showLogin();
          return;
        }
      }
    }

    if (item.title === 'Logout') {
      await logout();
      return;
    }

    if (item.title === 'Login') {
      showLogin();
      return;
    }
    if (item.title === 'Orders') {
      params.set(item.params, item.value);
      params.delete('openMenu');

    } else if (item.title === 'Address Book') {
      params.set(item.params, item.value);
    } else {
      params.set(item.params, true);
      params.delete('openMenu');
    }

    router.push({
      pathname: item.route,
      hash: item.hash,
      search: item?.params ? params.toString() : null,
    }, undefined, {
      shallow: !!item.shallow,
    })

  }

  const handleDetailsClose = () => {
    toggleUserDetails();
    params.delete('openMenu');
    params.set('openChat', 'true');
    router.push({ search: params.toString() });
  };

  return (
    <Drawer
      PaperProps={{
        classes: {
          root: cx(styles.paper, type === 'right' ? styles.paperRight : '')
        }
      }}
      anchor={type}
      open={true}
      onClose={() => {

        onClose();

      }}

    >
      {openUserDetails && <UserDetailsPopup
        onSubmit={handleDetailsClose}
        shop={shop}
        onClose={toggleUserDetails}
      />}
      <div className={styles.top}>
        <Clickable className={styles.closeIcon} onClick={onClose}>{closeIcon()}</Clickable>
        {menuList.map((menu, idx) => (
          <div key={idx} className={styles.menu}>
            <div className={styles.flex}>
              <div className={styles.icon}>{menu.icon}</div>
              <div
                role="button"
                tabIndex="0"
                className={styles.mainTitle}
                onClick={() => {
                  if (menu.title !== "Collections" && menu.title !== "Cart") {
                    if (menu.title === "Customer Testimonials") {
                      handleTestimonials(menu)
                      return
                    }
                    toggle(idx, setOpen, setIndex, index !== idx || !open)
                  }
                  else {
                    handleOnClick(menu)();
                  }
                }}
                onKeyPress={() => {
                }}
              >
                {menu.title === "Collections" ? menu.langKey :lang(menu.langKey)}
              </div>
              {menu.title !== "Collections" && menu.title !== "Cart" && menu.title !== "Customer Testimonials" && (
                <div className={styles.chevron}>
                  {(index === idx && open) ? (
                    <span
                      onClick={() => toggle(idx, setOpen, setIndex, index !== idx || !open)}>
                      {chevronUp()}
                    </span>
                  ) : (
                    <img
                      src="/images/menu/chevronDown.svg"
                      onClick={() => toggle(idx, setOpen, setIndex, index !== idx || !open)}
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
            {/* eslint-disable-next-line max-len */}
            <div className={cx(styles.subMenu, (index === idx && open) && classes, { [styles.emailMenu]: menu.title === 'My Account' })}>
              {menu.title === 'My Account' && (<div className={styles.marginLeft}>
                {email || profile.phone}
              </div>)}
              {menu?.items.length > 0 && menu?.items.map((item, idx) => (
                (
                  <div
                    key={item.title}
                    className={cx({ [styles.isDirect]: item.isDirect && item.title === 'Login' })}>
                    <Clickable
                      key={idx}
                      onClick={handleOnClick(item)}
                      className={cx(styles.link)}
                    >
                      <div className={styles.icons}>{item.icon}</div>
                      <div className={styles.subMenuText}>{lang(item.langKey)}</div>
                    </Clickable>
                  </div>
                )
              ))}
            </div>
          </div>
        ))}
        <FollowUsAndMore shop={shop} variant="menu" />
        <div className="spacer" />
        <div className={styles.footer}>
          <div>
            {showBanner ? (<>
              <div className={styles.flex2}>
                <span>powered by</span>
                <span><img className={styles.wlogo} src="/images/menu/windoFooterLogo.svg" alt="" /></span>
                <span><img src="/images/menu/windo.svg" alt="" /></span>
              </div>
              <br />
              <div className={styles.tnc}>terms and privacy policy</div>
            </>) : (
              <div className={styles.flex2}>
                <span className={styles.text2}>Copyright &copy; {shop.name}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {showAddress && (
        <Address
          type="menu"
          shop={shop}
          isMenuBar={true}
        />
      )}
      {chatEnabled && showChat && (
        <CustomShopChat
          shopId={shop?._id}
          shop={{ name: shop?.name, }}
          slug={shop?.slug}
        />
      )}
    </Drawer>
  );

}

Menu.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  shop: PropTypes.object.isRequired,
};

export default Menu;
