import React from 'react';
import cx from 'classnames';
import { Button } from '@mui/material';
import Menu from 'components/common/MenuBar';
import { Clickable } from 'phoenix-components';
import { hamIcon, newCartIcon, newSearchIcon } from 'components/shared/Icons/Header';
import { useRouter } from 'next/router';
import { useIsCustomDomain, useIsVisitor, useShowLogin } from 'contexts/profile';
import { useQueryParams } from 'hooks/location';
import CustomShopChat from 'components/shop/CustomShopChat';
import { getUserIcon } from 'components/utils/icon';
import styles from './Header.module.css';
import { useAutoFontSize } from 'hooks/styles';
import { useThemeName } from 'hooks/shop';
import { useLocalCart } from 'contexts/cart';
import _ from 'ui/lodash'

function Header({
  // showProfile,
  myOrders,
  showSearch,
  showMenu,
  showBack,
  onBack,
  showLogout,
  showCart,
  payload,
  showShop,
  dataId,
  text,
  headerStyles,
  label,
  showChat,
  shop,
  isLandingPage,
}) {

  const router = useRouter();
  const isVisitor = useIsVisitor();
  const showLogin = useShowLogin(shop);
  // const id = useProfileId();
  const params = useQueryParams();
  const menu = params.has('openMenu');
  // const showLogoutMenu = params.has('showLogout');
  const cartData = useLocalCart();
  const productsCount = _.sumBy(cartData?.products || [], 'quantity');
  const isCustomDomain = useIsCustomDomain();
  const themeName = useThemeName(shop);
  const ref = useAutoFontSize(themeName, label);

  const onClose = () => {

    params.delete('openMenu');
    router.push({ search: params.toString() }, undefined, { shallow: true });

  };

  const onBackAction = () => {

    if (showShop && !showBack) {

      const url = isCustomDomain ? '/' : payload?.shop?.pathname;
      if (url) {

        router.replace(url);
        return;

      }
      router.push({ pathname: '/' });
      return;

    }
    if (onBack) {

      onBack();
      return;

    }
    router.back();

  };

  const shopIcon = payload?.shop?.icon;
  const emptyShopIcon = '/images/store/back.svg';

  return (
    <div className={cx('flexBetween', headerStyles)}>
      <div className={cx(styles.bigName, 'flexBetween fullWidth')}>
        {!isLandingPage && showShop && (
          <div className={styles.shop}>
            <div className={cx({ [styles.default]: !shopIcon && !showBack })}>
              <img
                src={showBack && !myOrders ? '/images/feed/backButton.svg' 
                  : myOrders ? null : shopIcon || emptyShopIcon}
                alt=""
                data-id={dataId}
                className={cx({
                  [styles.shopIcon]: !showBack,
                  invert: !shopIcon && !showBack,
                  [styles.emptyIcon]: !shopIcon && !showBack
                })}
                data-value="shop button"
                role="none"
                onClick={!myOrders ? onBackAction : null}/>
            </div>
            <div
              role="none"
              data-id={dataId}
              ref={ref}
              data-value="shop button"
              onClick={!myOrders ? onBackAction : null}
              className={cx(styles.right)}>
              {label}
            </div>
          </div>
        )}
        {isLandingPage && (
          <img className={styles.landingPageLogo} src="/images/logo_png.png" alt="" />
        )}
        <div className={styles.flex}>
          {text && (
            <>
              {' '}
              {text}
              {' '}
            </>
          )}
          {showSearch && (
            <span
              role="presentation"
              className={cx(styles.newIcon)}
              data-id={dataId}
              data-value="search button"
              onClick={() => {
                router.push({
                  pathname: isCustomDomain
                    ? '/search' : `/${shop?.slug}/search`,
                  search: params.toString()
                });

              }}
            >
              {newSearchIcon()}
            </span>
          )}
          {showChat && (
            <CustomShopChat
              shopId={shop?._id}
              shop={{
                ...shop,
                name: shop?.name,
                url: getUserIcon(shop)
              }}
              slug={shop?.slug}
            />
          )}
          {showCart && (
            <div
              className={cx(styles.newIcon, styles.relative)}
              role="none"
              onClick={() => {

                if (isVisitor) {
                  showLogin(shop);
                  return;
                }

                // if (productsCount === 0) {
                //   const url = isCustomDomain ? '/orders' : `/${(shop?.slug || payload?.shop?.name)}/orders`;
                //   router.push(url);
                //   return;
                // }
                const url = isCustomDomain ? '/cart' : `/${(shop?.slug || payload?.shop?.name)}/cart`;
                router.push(url);
              }}
            >
              {newCartIcon()}
              {productsCount > 0 && <span className={styles.count}>{productsCount}</span>}
            </div>
          )}
          {showMenu && (
            <Clickable
              className={cx(styles.newIcon)}
              onClick={() => {

                params.set('openMenu', 'true');
                router.push({
                  search: params.toString(),
                }, undefined, { shallow: true });

              }}
            >
              {hamIcon()}
            </Clickable>
          )}
          {showLogout && (
            <Button
              color="secondary"
              className={styles.showSearch}
              onClick={() => {

                params.set('showLogout', 'true');
                router.push({
                  search: params.toString(),
                });

              }}>
              <img src="/images/profile/more.svg" alt="" />
            </Button>
          )}
        </div>
      </div>
      {menu && <Menu shop={shop} type="right" onClose={onClose} />}
    </div>
  );

}

Header.defaultProps = {
  // showProfile: false,
  myOrders: false,
  showSearch: false,
  showMenu: false,
  showBack: false,
  text: '',
  showLogout: false,
  showShop: false,
  showCart: false,
  payload: {},
  dataId: '',
  headerStyles: '',
  label: '',
  onBack: null,
  showChat: false,
  shop: {},
  isLandingPage: false,
};

export default Header;
