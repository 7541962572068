import axios from './axios';

export const readChat = async (room, ids) => {
  const { data } = await axios.post('/chat/readChat', {
    room,
    ids
  });
  return data;
};

export const getChat = async (room, page) => {
  const { data } = await axios.get('/chat/getChat', {
    params: {
      room,
      page
    }
  });
  return data;
};

export const sendMessage = async (room, payload) => {
  const { data } = await axios.post('/chat/sendMessage', {
    room,
    payload
  });
  return data;
};

export const orderRoom = async (page, id) => {
  const { data } = await axios.get('/chat/orderRoom', {
    params: {
      page,
      id
    }
  });
  return data;
};

export const shopRoom = async (shopId) => {
  const { data } = await axios.get('/chat/shopRoom', {
    params: {
      id: shopId,
    }
  });
  return data;
};
