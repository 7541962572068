import { useEffect, useState } from 'react';
import {
  readChat, getChat, sendMessage, orderRoom, shopRoom
} from 'ui/api/chat';
import {
  isUserIdle
} from 'contexts/profile';
import { SnackBar } from 'ui/services';
import _ from 'ui/lodash';
import dayjs from 'dayjs';

function morphChats(chats) {
  return _.sortBy(Object.entries(
    _.groupBy(chats, c => dayjs(c.at).format('DD/MM/YYYY'))
  ).map(([key, data]) => ({
    key,
    data,
  })), s => dayjs(s.key));
}

export function useInfiniteRoom(roomId) {
  const [chats, setChats] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const isIdle = isUserIdle();

  const readMessages = async chats => {
    try {
      const ids = chats.filter(x => !x.read).map(x => x._id);
      if (ids.length === 0) {
        return;
      }
      await readChat(roomId, ids);
    } catch (e) {
      SnackBar.showError(e);
      console.error(e);
    }
  };

  const loadMore = async () => {
    if (!hasMore) {
      return;
    }
    try {
      const data = await getChat(roomId, page);
      readMessages(data);
      setChats(_.sortBy(_.uniqBy([...chats, ...data], '_id'), c => dayjs(c.at)));
      setNames(_.uniq([...names, ...data.filter(s => s.by !== 'you').map(s => s.by.userName)]));
      if (data.length === 0) {
        setHasMore(false);
      }
      setPage(page + 1);
    } catch (e) {
      console.error(e);
      SnackBar.show('Error Occurred', 'error');
    } finally {
      setLoading(false);
    }
  };

  const poll = async () => {
    if (isIdle) {
      return;
    }
    try {
      const data = await getChat(roomId, 0);
      setChats(_.sortBy(_.uniqBy([...chats, ...data], '_id'), c => dayjs(c.at)));
      setNames(_.uniq([...names, ...data.filter(s => s.by !== 'you').map(s => s.by.userName)]));
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  const refresh = () => {
    setPage(0);
    setHasMore(true);
    setChats([]);
    setTimeout(loadMore, 500);
  };
  useEffect(() => {
    if (isIdle) {
      return;
    }
    const interval = setInterval(poll, 15000);
    refresh();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [roomId]);

  return [
    morphChats(chats),
    names,
    loadMore,
    poll,
    hasMore,
    loading,
    refresh
  ];
}

export function useChatSendMessage() {
  const addMessage = async (roomId, payload) => {
    try {
      await sendMessage(roomId, payload);
    } catch (e) {
      SnackBar.showError(e);
      console.error(e);
    }
  };
  return [addMessage];
}

export function useOrderChat() {
  const getRoom = async (page, roomId) => {
    try {
      return await orderRoom(page, roomId);
    } catch (e) {
      SnackBar.showError(e);
      console.error(e);
    }
  };
  return [getRoom];
}

export function useShopChat() {
  const getRoom = async (shopId) => {
    try {
      return await shopRoom(shopId);
    } catch (e) {
      SnackBar.showError(e);
      console.error(e);
    }
  };
  return [getRoom];
}
