/* eslint-disable max-len */
export function cartIcon() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M28.8284 8.68571C28.6262 8.37143 28.2795 8.14285 27.9039 8.14285L9.64489 7.31428L9.00929 4.85714C8.86484 4.34286 8.43147 4 7.88255 4H4.15563C3.52003 4 3 4.51429 3 5.14286C3 5.77143 3.52003 6.28571 4.15563 6.28571H6.98693L10.1649 18.5143L9.38487 21.7143C9.2982 22.0571 9.38487 22.4286 9.58711 22.6857C9.81823 22.9714 10.136 23.1143 10.4827 23.1143H24.6392C25.2748 23.1143 25.7949 22.6 25.7949 21.9714C25.7949 21.3428 25.2748 20.8286 24.6392 20.8286H11.985L12.3028 19.5143L23.8592 18.9714C24.2925 18.9428 24.6681 18.6857 24.8415 18.3143L28.8862 9.74285C29.0595 9.42857 29.0306 9.02857 28.8284 8.68571Z"
        fill="var(--primary)" />
      <path d="M11.234 28C12.4306 28 13.4008 27.0406 13.4008 25.8572C13.4008 24.6737 12.4306 23.7143 11.234 23.7143C10.0373 23.7143 9.06714 24.6737 9.06714 25.8572C9.06714 27.0406 10.0373 28 11.234 28Z" fill="var(--primary)" />
      <path d="M24.2347 28C25.4314 28 26.4015 27.0406 26.4015 25.8572C26.4015 24.6737 25.4314 23.7143 24.2347 23.7143C23.038 23.7143 22.0679 24.6737 22.0679 25.8572C22.0679 27.0406 23.038 28 24.2347 28Z" fill="var(--primary)" />
    </svg>
  );

}

export function hamIcon() {

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" fill="white" />
      <path d="M24.0833 7.97791H3.91667C3.67375 7.97722 3.44097 7.8728 3.2692 7.68749C3.09743 7.50217 3.00065 7.25103 3 6.98896C3.00065 6.72688 3.09743 6.47574 3.2692 6.29043C3.44097 6.10511 3.67375 6.0007 3.91667 6H24.0833C24.3263 6.0007 24.559 6.10511 24.7308 6.29043C24.9026 6.47574 24.9994 6.72688 25 6.98896C24.9994 7.25103 24.9026 7.50217 24.7308 7.68749C24.559 7.8728 24.3263 7.97722 24.0833 7.97791Z" fill="var(--primary)" />
      <path d="M24.0833 14.9894H3.91667C3.67375 14.9887 3.44097 14.8843 3.2692 14.699C3.09743 14.5136 3.00065 14.2625 3 14.0004C3.00065 13.7384 3.09743 13.4872 3.2692 13.3019C3.44097 13.1166 3.67375 13.0122 3.91667 13.0115H24.0833C24.3263 13.0122 24.559 13.1166 24.7308 13.3019C24.9026 13.4872 24.9994 13.7384 25 14.0004C24.9994 14.2625 24.9026 14.5136 24.7308 14.699C24.559 14.8843 24.3263 14.9887 24.0833 14.9894Z" fill="var(--primary)" />
      <path d="M24.0833 22.0001H3.91667C3.67375 21.9994 3.44097 21.895 3.2692 21.7097C3.09743 21.5244 3.00065 21.2732 3 21.0112C3.00065 20.7491 3.09743 20.498 3.2692 20.3126C3.44097 20.1273 3.67375 20.0229 3.91667 20.0222H24.0833C24.3263 20.0229 24.559 20.1273 24.7308 20.3126C24.9026 20.498 24.9994 20.7491 25 21.0112C24.9994 21.2732 24.9026 21.5244 24.7308 21.7097C24.559 21.895 24.3263 21.9994 24.0833 22.0001Z" fill="var(--primary)" />
    </svg>

  );

}

export function newCartIcon() {

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" fill="white" />
      <path d="M23.8614 7.90474C23.6981 7.64284 23.418 7.45236 23.1147 7.45236L8.36703 6.76189L7.85366 4.71428C7.73698 4.28571 7.38696 4 6.9436 4H3.9334C3.42003 4 3 4.42857 3 4.95238C3 5.47618 3.42003 5.90475 3.9334 5.90475H6.22022L8.78705 16.0952L8.15701 18.7618C8.08701 19.0475 8.15701 19.3571 8.32036 19.5713C8.50704 19.8094 8.76372 19.9285 9.04374 19.9285H20.4778C20.9912 19.9285 21.4112 19.4999 21.4112 18.9761C21.4112 18.4523 20.9912 18.0237 20.4778 18.0237H10.2572L10.5138 16.9285L19.8478 16.4761C20.1978 16.4523 20.5012 16.238 20.6412 15.9285L23.9081 8.78569C24.0481 8.52379 24.0247 8.19045 23.8614 7.90474Z" fill="var(--primary)" />
      <path d="M9.65051 23.9999C10.6171 23.9999 11.4006 23.2004 11.4006 22.2142C11.4006 21.228 10.6171 20.4285 9.65051 20.4285C8.68394 20.4285 7.90039 21.228 7.90039 22.2142C7.90039 23.2004 8.68394 23.9999 9.65051 23.9999Z" fill="var(--primary)" />
      <path d="M20.151 23.9999C21.1176 23.9999 21.9011 23.2004 21.9011 22.2142C21.9011 21.228 21.1176 20.4285 20.151 20.4285C19.1844 20.4285 18.4009 21.228 18.4009 22.2142C18.4009 23.2004 19.1844 23.9999 20.151 23.9999Z" fill="var(--primary)" />
    </svg>

  );

}

export function newSearchIcon() {

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" fill="white" />
      <path d="M12.2222 18.4441C15.6586 18.4441 18.4444 15.6584 18.4444 12.2221C18.4444 8.78571 15.6586 6 12.2222 6C8.78578 6 6 8.78571 6 12.2221C6 15.6584 8.78578 18.4441 12.2222 18.4441Z" stroke="var(--primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.9998 22.0002L16.6665 16.667" stroke="var(--primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );

}

export function salviaSearchIcon() {

  return (
    <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.74972 0.666992C6.62022 0.667088 5.50711 0.937296 4.50327 1.45507C3.49943 1.97285 2.63397 2.72317 1.97909 3.64345C1.32421 4.56373 0.898898 5.62728 0.738647 6.74536C0.578395 7.86344 0.687847 9.00363 1.05787 10.0708C1.4279 11.138 2.04776 12.1012 2.86575 12.8801C3.68375 13.659 4.67614 14.231 5.76015 14.5483C6.84416 14.8657 7.98834 14.9192 9.09724 14.7044C10.2061 14.4896 11.2476 14.0128 12.1347 13.3137L15.1781 16.357C15.3352 16.5088 15.5457 16.5928 15.7642 16.5909C15.9827 16.589 16.1917 16.5013 16.3462 16.3468C16.5007 16.1923 16.5884 15.9833 16.5903 15.7648C16.5922 15.5463 16.5082 15.3358 16.3564 15.1787L13.3131 12.1353C14.1364 11.0908 14.649 9.83566 14.7923 8.51343C14.9356 7.1912 14.7037 5.85534 14.1232 4.65874C13.5428 3.46213 12.6371 2.45312 11.51 1.74718C10.3828 1.04125 9.07969 0.666903 7.74972 0.666992V0.666992ZM2.33306 7.75033C2.33306 6.31374 2.90374 4.93599 3.91956 3.92016C4.93538 2.90434 6.31313 2.33366 7.74972 2.33366C9.18631 2.33366 10.5641 2.90434 11.5799 3.92016C12.5957 4.93599 13.1664 6.31374 13.1664 7.75033C13.1664 9.18692 12.5957 10.5647 11.5799 11.5805C10.5641 12.5963 9.18631 13.167 7.74972 13.167C6.31313 13.167 4.93538 12.5963 3.91956 11.5805C2.90374 10.5647 2.33306 9.18692 2.33306 7.75033V7.75033Z" fill="#E7E7E7"/>
    </svg>
  );
}
export function lupineSearchIcon() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="var(--primary)" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.95249 0.25C6.76651 0.250101 5.59775 0.533819 4.54372 1.07748C3.48969 1.62115 2.58095 2.40899 1.89332 3.37528C1.2057 4.34158 0.759126 5.4583 0.590862 6.63229C0.422598 7.80627 0.537523 9.00348 0.926048 10.124C1.31457 11.2445 1.96543 12.2559 2.82432 13.0738C3.68322 13.8916 4.72523 14.4922 5.86344 14.8254C7.00165 15.1586 8.20304 15.2148 9.36738 14.9893C10.5317 14.7638 11.6253 14.2631 12.5567 13.529L15.7522 16.7245C15.9173 16.8839 16.1383 16.9721 16.3677 16.9701C16.5971 16.9681 16.8166 16.8761 16.9788 16.7138C17.1411 16.5516 17.2331 16.3321 17.2351 16.1027C17.2371 15.8733 17.1489 15.6523 16.9895 15.4873L13.794 12.2918C14.6585 11.195 15.1968 9.8771 15.3472 8.48876C15.4976 7.10042 15.2542 5.69777 14.6447 4.44133C14.0352 3.1849 13.0843 2.12544 11.9008 1.3842C10.7172 0.642968 9.34896 0.249906 7.95249 0.25ZM2.26499 7.6875C2.26499 6.17908 2.86421 4.73244 3.93082 3.66583C4.99744 2.59922 6.44407 2 7.95249 2C9.46091 2 10.9075 2.59922 11.9742 3.66583C13.0408 4.73244 13.64 6.17908 13.64 7.6875C13.64 9.19592 13.0408 10.6426 11.9742 11.7092C10.9075 12.7758 9.46091 13.375 7.95249 13.375C6.44407 13.375 4.99744 12.7758 3.93082 11.7092C2.86421 10.6426 2.26499 9.19592 2.26499 7.6875Z" fill="var(--primary)"/>
    </svg>
  );
}

export function salviaCartIcon() {

  return (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.77172 16.9995C6.21465 16.9995 6.57372 16.6404 6.57372 16.1975C6.57372 15.7546 6.21465 15.3955 5.77172 15.3955C5.32879 15.3955 4.96973 15.7546 4.96973 16.1975C4.96973 16.6404 5.32879 16.9995 5.77172 16.9995Z" stroke="#E7E7E7" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.594 16.9995C15.0369 16.9995 15.396 16.6404 15.396 16.1975C15.396 15.7546 15.0369 15.3955 14.594 15.3955C14.1511 15.3955 13.792 15.7546 13.792 16.1975C13.792 16.6404 14.1511 16.9995 14.594 16.9995Z" stroke="#E7E7E7" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 1H2.60399L4.73731 10.9608C4.81556 11.3256 5.01854 11.6517 5.31131 11.883C5.60407 12.1143 5.9683 12.2363 6.3413 12.228H14.1848C14.5499 12.2274 14.9038 12.1023 15.1882 11.8733C15.4725 11.6444 15.6702 11.3253 15.7487 10.9688L17.072 5.00999H3.46213" stroke="#E7E7E7" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export function lupineHamIcon () {
  return(
    <svg width="30" height="17" viewBox="0 0 30 17" fill="var(--primary)" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.76367 0.5C9.82454 0.5 10.842 0.921427 11.5921 1.67157C12.3422 2.42172 12.7637 3.43913 12.7637 4.5C12.7637 5.56087 12.3422 6.57828 11.5921 7.32843C10.842 8.07857 9.82454 8.5 8.76367 8.5C7.70281 8.5 6.68539 8.07857 5.93524 7.32843C5.1851 6.57828 4.76367 5.56087 4.76367 4.5C4.76367 3.43913 5.1851 2.42172 5.93524 1.67157C6.68539 0.921427 7.70281 0.5 8.76367 0.5ZM8.76367 2.5C8.23324 2.5 7.72453 2.71071 7.34946 3.08579C6.97439 3.46086 6.76367 3.96957 6.76367 4.5C6.76367 5.03043 6.97439 5.53914 7.34946 5.91421C7.72453 6.28929 8.23324 6.5 8.76367 6.5C9.2941 6.5 9.80281 6.28929 10.1779 5.91421C10.553 5.53914 10.7637 5.03043 10.7637 4.5C10.7637 3.96957 10.553 3.46086 10.1779 3.08579C9.80281 2.71071 9.2941 2.5 8.76367 2.5ZM8.76367 9.5C11.4337 9.5 16.7637 10.83 16.7637 13.5V16.5H0.763672V13.5C0.763672 10.83 6.09367 9.5 8.76367 9.5ZM8.76367 11.4C5.79367 11.4 2.66367 12.86 2.66367 13.5V14.6H14.8637V13.5C14.8637 12.86 11.7337 11.4 8.76367 11.4Z" fill="var(--primary)"/>
      <path d="M29.5856 6.49078L25.5961 10.3733C25.5486 10.4194 25.4972 10.4519 25.4418 10.471C25.3864 10.4903 25.327 10.5 25.2637 10.5C25.2003 10.5 25.141 10.4903 25.0856 10.471C25.0302 10.4519 24.9787 10.4194 24.9312 10.3733L20.9299 6.49078C20.8191 6.38326 20.7637 6.24885 20.7637 6.08756C20.7637 5.92627 20.823 5.78802 20.9418 5.67281C21.0605 5.5576 21.199 5.5 21.3573 5.5C21.5157 5.5 21.6542 5.5576 21.7729 5.67281L25.2637 9.05991L28.7544 5.67281C28.8653 5.56528 29.0017 5.51152 29.1638 5.51152C29.3263 5.51152 29.4668 5.56912 29.5856 5.68433C29.7043 5.79954 29.7637 5.93395 29.7637 6.08756C29.7637 6.24117 29.7043 6.37558 29.5856 6.49078Z" fill="var(--primary)"/>
    </svg>
  );
}

export function salviaHamIcon() {

  return (
    <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4C6 4.53043 6.21071 5.03914 6.58579 5.41421C6.96086 5.78929 7.46957 6 8 6C8.53043 6 9.03914 5.78929 9.41421 5.41421C9.78929 5.03914 10 4.53043 10 4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 8.53043 2 8 2ZM8 9C10.67 9 16 10.33 16 13V16H0V13C0 10.33 5.33 9 8 9ZM8 10.9C5.03 10.9 1.9 12.36 1.9 13V14.1H14.1V13C14.1 12.36 10.97 10.9 8 10.9Z" fill="#E7E7E7"/>
      <path d="M28.8219 5.99078L24.8325 9.87327C24.785 9.91935 24.7335 9.95192 24.6781 9.97097C24.6227 9.99032 24.5633 10 24.5 10C24.4367 10 24.3773 9.99032 24.3219 9.97097C24.2665 9.95192 24.215 9.91935 24.1675 9.87327L20.1662 5.99078C20.0554 5.88326 20 5.74885 20 5.58756C20 5.42627 20.0594 5.28802 20.1781 5.17281C20.2968 5.0576 20.4354 5 20.5937 5C20.752 5 20.8905 5.0576 21.0092 5.17281L24.5 8.55991L27.9908 5.17281C28.1016 5.06528 28.238 5.01152 28.4002 5.01152C28.5626 5.01152 28.7032 5.06912 28.8219 5.18433C28.9406 5.29954 29 5.43395 29 5.58756C29 5.74117 28.9406 5.87558 28.8219 5.99078Z" fill="#E7E7E7"/>
    </svg>
  );
}

export function hamMobileIcon() {
  
  return (
    <svg width="16" height="12" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.57227 13.5H17.0723H9.57227ZM1.07227 7.5H17.0723H1.07227ZM1.07227 1.5H17.0723H1.07227Z" fill="#E7E7E7"/>
      <path d="M9.57227 13.5H17.0723M1.07227 7.5H17.0723M1.07227 1.5H17.0723" stroke="#E7E7E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ); 
}
export function lupineCartIcon () {
  return (
    <svg width="30" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.53637 17.5005C6.9793 17.5005 7.33837 17.1414 7.33837 16.6985C7.33837 16.2556 6.9793 15.8965 6.53637 15.8965C6.09344 15.8965 5.73438 16.2556 5.73438 16.6985C5.73438 17.1414 6.09344 17.5005 6.53637 17.5005Z" stroke="var(--primary)" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.3567 17.5005C15.7996 17.5005 16.1587 17.1414 16.1587 16.6985C16.1587 16.2556 15.7996 15.8965 15.3567 15.8965C14.9138 15.8965 14.5547 16.2556 14.5547 16.6985C14.5547 17.1414 14.9138 17.5005 15.3567 17.5005Z" stroke="var(--primary)" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.76367 1.5H3.36767L5.50098 11.4608C5.57924 11.8256 5.78222 12.1517 6.07498 12.383C6.36774 12.6143 6.73197 12.7363 7.10497 12.728H14.9485C15.3136 12.7274 15.6675 12.6023 15.9518 12.3733C16.2362 12.1444 16.4339 11.8253 16.5124 11.4688L17.8357 5.50999H4.2258" stroke="var(--primary)" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
}

export function mobileHamIcon () {
  return (
    <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4173 8H20.0007ZM1.33398 1H20.0007Z" fill="var(--primary)"/>
      <path d="M12.4173 8H20.0007M1.33398 1H20.0007" stroke="var(--primary)" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}